import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import { paginateArray, sortCompare } from "@/@fake-db/utils";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BJumbotron } from "bootstrap-vue";
// import { is } from "core-js/core/object";

const getFirebaseData = async () => {
  let productsList = [];
  // console.log(store.state);
  var collegeCode = store.state.mainUserCodes.CollegeCode;
  var studentCode = store.state.mainUserCodes.StudentCode;

  let user_uid = store.state.mainUserCodes.user_uid;
  // console.log(collegeCode);

  let result = await new Promise((resolve, reject) => {
    let db = firebase.firestore();
    db.collection("RecruiterId")
      .where("fromCollege", "array-contains", collegeCode)
      .get()
      .then(async (snap) => {
        // console.log(snap.size)
        let deletecount = 0;
        for (let doc of snap.docs) {
          let blockedBy = doc.data().blockedBy ?? [];

          let deletedBy = doc.data().deletedBy ?? [];
          let obj = doc.data();
          // console.log(obj)
          let totalOpenings = 0;
          obj.isBlocked = blockedBy.includes(collegeCode);
          obj.isDeleted = deletedBy.includes(collegeCode);
          obj.id = doc.id;
          if (obj.isDeleted) {
            ++deletecount;
          } else {
            let jobRef = await db
              .collection("RecruiterJobs")
              .where("RecruiterCode", "==", obj.RecruiterCode)
              .where("Colleges", "==", studentCode)
              .get();
            // console.log(jobRef.size)
            if (jobRef.size > 0) {
              jobRef.forEach((jobdoc) => {
                totalOpenings += parseInt(jobdoc.data().vacancies);
              });
            }
            let internRef = await db
              .collection("RecruiterInternships")
              .where("RecruiterCode", "==", obj.RecruiterCode)
              .where("Colleges", "==", studentCode)
              .get();
            // console.log(jobRef.size)
            if (internRef.size > 0) {
              internRef.forEach((jobdoc) => {
                totalOpenings += parseInt(jobdoc.data().vacancies);
              });
            }

            obj.totalOpenings = totalOpenings;
            productsList.push(obj);
          }
        }
        // console.log(productsList)

        // if (productsList.length == snap.size){
        //   console.log(Recruiterlist.length-c)
        if (productsList.length == snap.size - deletecount) {
          // console.log(productsList)

          resolve(productsList);
        }
      })
      .then(() => {});

  });
  return result;
};

let users = [];
let filteredData = [];
const getDataandSort = async (config) => {
  if (users.length < 1 || config.isValueUpdated == true) {
    // console.log("this is users length");
    // console.log(users.length);
    users = await getFirebaseData();
    // console.log(users)
  }
  // console.log('hi this users');
  const {
    q = "",
    perPage = 10,
    page = 1,
    sortBy = "id",
    sortDesc = false,
    role = [],
    plan = [],
    placed = null,
  } = config;

  const queryLowered = q.toLowerCase();
  // const filteredData = users;
  // console.log(filteredData.length);
  let user = users.filter(
    (user) =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (user.CompanyName ? user.CompanyName.toLowerCase().includes(queryLowered) : null) ||
       user.ContactName.toLowerCase().includes(queryLowered)
    
  );
  const sortedData = user.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();
  filteredData = sortedData;
  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: sortedData.length,
      rawUsers: users,
    },
  ];
};

export function rawUserList() {
  const rawList = ref([]);

  rawList.value = users;

  return { rawList };
}
// export function filterdUserList() {
//   const filteredList=ref([]);

//   filteredList.value= filteredData;

//   return { filteredList};
// }
export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "ContactName",
      label: "Name",
      formatter: title,
      sortable: true,
    },
    {
      key: "email",
      label: "email",
      formatter: title,
      sortable: true,
    },
    {
      key: "CompanySector",
      label: "sector",
      formatter: title,
      sortable: true,
    },
    {
      key: "ContactNo",
      label: "Contact",
      formatter: title,
      sortable: true,
    },
    {
      key: "totalOpenings",
      label: "Total Openings",
      formatter: title,
      sortable: true,
    },

    { key: "status", sortable: true },
    // { key: 'email', sortable: true },
    // { key: 'role', sortable: true },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const placedFilter = ref(null);
  const rawList = ref([]);
  const filteredList = ref([]);
  const isValueUpdated = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = (val) => {
    // console.log("refetching....");
    refUserListTable.value.refresh();
    // console.log(refUserListTable)
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      roleFilter,
      planFilter,
      placedFilter,
      isValueUpdated,
    ],
    () => {
      refetchData();
    }
  );

  const fetchUsers = (ctx, callback) => {
    // store
    //   .dispatch('app-user/fetchUsers', {
    //     q: searchQuery.value,
    //     perPage: perPage.value,
    //     page: currentPage.value,
    //     sortBy: sortBy.value,
    //     sortDesc: isSortDirDesc.value,
    //     role: roleFilter.value,
    //     plan: planFilter.value,
    //     placed: placedFilter.value,
    //   })
    // console.log(`this searchQuery obj ${searchQuery.value.length}`);
    getDataandSort({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      isValueUpdated: isValueUpdated.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role: roleFilter.value ?? [],
      plan: planFilter.value ?? [],
      placed: placedFilter.value,
    })
      .then((response) => {
        // console.log(response);
        const { users, total, rawUsers } = response[1];
        // console.log(rawUsers);
        // console.log('here we have raw users');
        rawList.value = rawUsers;
        filteredList.value = filteredData;
        callback(users);
        totalUsers.value = total;
        isValueUpdated.value = false;
      })
      .catch((err) => {
        // console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === true) return "success";
    if (status === false) return "secondary";
    return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    isValueUpdated,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    rawList,
    filteredList,

    // Extra Filters
    roleFilter,
    planFilter,
    placedFilter,
  };
}
